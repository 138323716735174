<template>
  <div id="sonic.water.input.piping.part.form.pipe">
    <part-length />
    <part-sizing />
    <div v-if="part.inletSizing === 'COSTUM'">
      <part-inlet-size-costum />
    </div>
    <div v-else-if="part.inletSizing === 'DIN'">
      <part-inlet-size-din />
    </div>
    <div v-else-if="part.inletSizing === 'ANSI'">
      <part-inlet-size-ansi />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import partLength from '@/views/sonic/form/piping/length'
import partSizing from '@/views/sonic/form/piping/inletSizing'
import partInletSizeCostum from '@/views/sonic/form/piping/inletSizeCostum'
import partInletSizeDin from '@/views/sonic/form/piping/inletSizeDIN'
import partInletSizeAnsi from '@/views/sonic/form/piping/inletSizeANSI'
export default {
  name: 'sonic.water.input.piping.part.form.pipe',
  components: {
    partLength,
    partSizing,
    partInletSizeCostum,
    partInletSizeDin,
    partInletSizeAnsi
  },
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    })
  }
}
</script>

<style>

</style>