<template>
  <div id="sonic.water.input.general.form">
    <b-form-group class="mb-2">
      <label for="name-live">{{$t('sonic.form.project.name.label')}}:</label>
      <b-input-group>
        <b-form-input id="name-live" type="text" v-model="sonicName" :disabled="true"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group class="mb-2">
      <label for="desc-live">{{$t('sonic.form.project.description.label')}}:</label>
      <b-input-group>
        <b-form-textarea id="desc-live" type="text" v-model="sonicDescription" rows="3" :disabled="true"></b-form-textarea>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "sonic.water.input.general.form",
  computed: {
    ...mapGetters({
      project: "getSonicProject",
      name: "getSonicStoredName",
      description: "getSonicStoredDescription"
    }),
    sonicName() {
      return this.project.name || this.name || ""
    },
    sonicDescription() {
      return this.project.description || this.description || ""
    }
  }
};
</script>

<style>
</style>