<template>
  <div id="sonic.water.input.properties.index">
    <b-row>
      <b-col md="12" class="text-center">
        <h1 class="display-4">{{$t('sonic.project.properties.title')}}</h1>
        <hr />
      </b-col>
    </b-row>
    <properties-form />
  </div>
</template>

<script>
import propertiesForm from "./form"
export default {
  name: 'sonic.water.input.properties.index',
  components: {
    propertiesForm
  }
}
</script>

<style>

</style>