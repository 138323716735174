<template>
  <div id="sonic.water.input.piping.part.index">
    <b-modal
      id="modal-sonic-piping-add-part"
      scrollable
      no-close-on-backdrop
      title="Add part"
      @ok="ok"
      @cancel="cancel"
    >
      <part-form />
      <template v-slot:modal-footer>
        <b-button type="button" variant="success" @click="ok()" :disabled="!validPart">
          <div v-if="part.edit===false">
            <i class="fas fa-save"></i>
            {{$t('sonic.form.piping.buttons.save')}}
          </div>
          <div v-else>
            <i class="fas fa-save"></i>
            {{$t('sonic.form.piping.buttons.edit')}}
          </div>
        </b-button>
        <b-button type="button" variant="danger" @click="cancel()">{{$t('sonic.form.piping.buttons.cancel')}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import partForm from './form/index'
import logic from '@/scripts'
export default {
  name: 'sonic.water.input.piping.part.index',
  components: {
    partForm,
  },
  computed: {
    ...mapGetters({
      part: "getSonicPartForm"
    }),
    validPart() {
      return logic.input.sonic.parts.validate(this.part)
    }
  },
  methods: {
    ok() {
      this.$store.dispatch("storeSonicPartForm")
      this.$root.$emit("bv::hide::modal", "modal-sonic-piping-add-part");
    },
    cancel() {
      this.$store.dispatch("clearSonicPartForm");
      this.$root.$emit("bv::hide::modal", "modal-sonic-piping-add-part");
    },
  }
}
</script>

<style>

</style>