<template>
  <div id="sonic.water.input.general.checklist">
    <b-row>
      <b-col md="12">
        <h2 class="h2">{{$t('sonic.project.general.checkList')}}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <h3>
          <i v-if="items.properties === false" class="far fa-times-circle text-danger"></i>
          <i v-else class="far fa-check-square text-success"></i>
          {{$t('sonic.project.properties.title')}}
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <h3>
          <i v-if="items.piping === false" class="far fa-times-circle text-danger"></i>
          <i v-else class="far fa-check-square text-success"></i>
          {{$t('sonic.project.piping.title')}}
        </h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sonic.water.input.general.checklist",
  computed: {
    ...mapGetters({
      project: "isSonicProjectComplete",
      items: "isSonicProjectItemsComplete",
    }),
  },
};
</script>

<style>
</style>