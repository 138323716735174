<template>
  <div id="sonic.water.input.piping.table">
    <table class="table table-striped table-hover sticky-header">
      <thead>
        <tr>
          <th scope="col">{{$t('sonic.project.piping.table.order')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.name')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.type')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.length')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.flowCoefficient')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.inletSize')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.outletSize')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.edit')}}</th>
          <th scope="col">{{$t('sonic.project.piping.table.delete')}}</th>
        </tr>
      </thead>
      <draggable v-model="pipingParts" tag="tbody">
        <tr v-for="(part, index) in pipingParts" :key="index">
          <td scope="row">{{ index + 1 }}</td>
          <td scope="row">{{ part.name }}</td>
          <td scope="row">{{ part.type }}</td>
          <td scope="row">
            <div v-if="part.length === '' || part.length === null">---</div>
            <b v-else class="text-center">{{ part.length }} {{$tc('units.length.short',0)}}</b>
          </td>
          <td scope="row">
            <div v-if="part.flowValue == '' || part.flowValue == null">---</div>
            <b v-else class="text-center">{{ part.flowValue + ' ' + part.flowUnit }}</b>
          </td>
          <td scope="row">
            <div v-if="part.inletSizing === 'COSTUM'">{{part.costumInletSize}} {{$tc('units.diameter.short',0)}}</div>
            <div
              v-else-if="part.inletSizing === 'ANSI'"
            >{{ `NPS ${part.standardInletSize}" - Sch. ${part.standardInletNorm}` }}</div>
            <div
              v-else-if="part.inletSizing === 'DIN'"
            >{{ `DN ${part.standardInletSize} - PN ${part.standardInletNorm}` }}</div>
            <div v-else>{{$t('sonic.project.piping.empty.undefined')}}</div>
          </td>
          <td scope="row">
            <div v-if="part.outletSizing === 'COSTUM'">
              <div v-if="part.costumInletSize === ''">{{part.costumInletSize}} {{$tc('units.diameter.short',0)}}</div>
              <div v-else>{{part.costumOutletSizeUnit}} {{$tc('units.diameter.short',0)}}</div>
            </div>
            <div v-else-if="part.outletSizing === 'ANSI'">
              <div
                v-if="part.standardOutletSize === ''"
              >{{ `NPS ${part.standardInletSize}" - Sch. ${part.standardInletNorm}` }}</div>
              <div v-else>{{ `NPS ${part.standardOutletSize}" - Sch. ${part.standardOutletNorm}` }}</div>
            </div>
            <div v-else-if="part.outletSizing === 'DIN'">
              <div v-if="part.standardOutletSize === ''">{{ `DN ${part.standardInletSize} - PN ${part.standardInletNorm}` }}</div>
              <div v-else>{{ `DN ${part.standardOutletSize} - PN ${part.standardOutletNorm}` }}</div>
            </div>
            <div v-else>{{$t('sonic.project.piping.empty.undefined')}}</div>
          </td>
          <td scope="row">
            <div @click="editPart(index)">
              <b-icon icon="pencil-square" variant="primary" class="h5"></b-icon>
            </div>
          </td>
          <td scope="row">
            <div @click="deletePart(index)">
              <b-icon
                icon="trash"
                variant="danger"
                class="h5"
                :class="{ 'text-muted': !pipingLength }"
              ></b-icon>
            </div>
          </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable'

export default {
  name: "sonic.water.input.piping.table",
  display: "Table",
  order: 8,
  components: {
    draggable
  },
  computed: {
    ...mapGetters({
      piping: 'getSonicPipingForm',
    }),
    pipingLength() {
      return this.piping.length > 1 ? true : false
    },
    pipingParts: {
      get() {
        return this.piping
      },
      set(value) {
        this.$store.commit('setSonicPipingForm', value)
      }
    }
  },
  methods: {
    editPart(order) {
      this.$store.dispatch('editSonicPartForm',order)
      this.$root.$emit('bv::show::modal', 'modal-sonic-piping-add-part')
    },
    deletePart(order) {
      if (this.piping.length > 1) {
        this.$store.dispatch('deleteSonicPart',order)
      }
    },
  }
};
</script>

<style>
</style>