<template>
  <div id="sonic.input.index">
    <div v-if="settings.fluid === 'water'">
      <water />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import water from './water'
export default {
  name: 'sonic.input.index',
  components: {
    water
  },
  computed: {
    ...mapGetters({
      settings: "getSonicSettings"
    })
  }
}
</script>

<style>

</style>