<template>
    <b-button variant="primary"><i class="fas fa-spinner fa-spin"></i> Loading...</b-button>
</template>

<script>
export default {
    name: 'components.general.loadingButton'
}
</script>

<style>

</style>