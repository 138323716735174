<template>
  <div id="partOutletSizeAnsi">
    <b-form-group class="mb-2">
      <label
        for="size-outlet-live"
        v-if="part.type === 'pipe'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',0)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'valve'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',1)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'elbow'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',2)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'orifice'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',3)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'attemperator'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',4)})}}:</label>
      <b-row>
        <b-col md="6">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-outlet-valve-live" v-model="pipeSizeSelectOutlet">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.outletSize.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="item in sizingListOutlet"
                  :key="item.id"
                  :value="item.NPS"
                >{{`NPS ${item.NPS}"`}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
        <b-col md="6">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-outlet-valve-live" v-model="pipeNormSelectOutlet">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.outletNorm.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="(item, key) in normListOutlet"
                  :key="key"
                  :value="key"
                >{{`Sch. ${key}`}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "sonic.part.outletSize.ansi",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
      sizingListOutlet: "getSonicSizingListOutlet",
      normListOutlet: "getSonicNormListOutlet",
    }),
    pipeSizeSelectOutlet: {
      get() {
        return this.part.standardOutletSize;
      },
      set(value) {
        this.$store.dispatch("setSonicPartFormStandardOutletSize", value);
      },
    },
    pipeNormSelectOutlet: {
      get() {
        return this.part.standardOutletNorm;
      },
      set(value) {
        this.$store.commit("setSonicPartFormStandardOutletNorm", value);
      },
    },
  },
  validations: {
    outletSize: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>