<template>
  <div id="sonic.water.input.general.share">
    <b-modal
      centered
      no-close-on-backdrop
      title="Share project"
      :hide-footer="true"
      id="modal-share"
    >
      <template v-slot:default>
        <b-form-group label="Shareable link">
          <b-form-input type="text" v-model="shareLink" placeholder="link" :readonly="true"></b-form-input>
        </b-form-group>
        <b-form-group label="option">
          <b-form-radio-group
            id="radio-slots"
            v-model="selectedShare"
            :options="options"
            name="radio-options-slots"
          ></b-form-radio-group>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "sonic.water.input.general.share",
  data() {
    return {
      options: [
        { text: 'Colleberate link', value: 'access' },
        { text: 'Duplicate link', value: 'duplicate' },
        { text: 'Readable link', value: 'read', disabled: true }
      ]
    }
  },
  computed: {
    ...mapGetters({
      share_link: "getShareLink",
      selected_share: 'getSelectedShare',
    }),
    shareLink: {
      get() {
        return `http://localhost:8080/sonic?share=${this.share_link}`
      },
    },
    selectedShare: {
      get() {
        return this.selected_share
      },
      set(value) {
        this.$store.dispatch('shareProject', value)
      }
    },
  },
  methods: {
    openShareLink() {
      if (this.share_link === '') {
        this.$store.dispatch('shareProject', 'access')
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.show('modal-share')
          })
        })
      }
      
    },
  }
};
</script>

<style>
</style>