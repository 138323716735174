<template>
  <div id="partFlowCoeffientValve">
    <b-form-group class="mb-2" id="flow-coefficient-group">
      <label for="flow-coefficient-live">{{$t('sonic.form.piping.flowCoefficient.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="valve-options-live"
            v-model="fu"
            tabindex="-1"
            :options="$t('sonic.form.piping.flowCoefficient.valve.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="flow-coefficient-live"
          type="number"
          v-model="$v.fc.$model"
          :state="validateState('fc')"
          aria-describedby="flow-coefficient-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.fc.$anyError" id="flow-coefficient-feedback">
        <small
          v-if="!$v.fc.required"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.flowCoefficient.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "part.flowCoefficient.valve",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    fc: {
      get() {
        return this.part.flowValue;
      },
      set(value) {
        this.$store.commit("setSonicPartFormFlowValue", value);
      },
    },
    fu: {
      get() {
        return this.part.flowUnit;
      },
      set(value) {
        this.$store.commit("setSonicPartFormFlowUnit", value);
      },
    },
  },
  validations: {
    fc: {
      required,
      valid() {
        return logic.input.sonic.parts.flowCoefficient.validate(this.part.type, this.part.flowValue, this.part.flowUnit)
      }
    },
    fuValve: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>