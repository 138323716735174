<template>
  <div id="partType">
    <b-form-group class="mb-2">
      <label for="type-live">{{$t('sonic.form.piping.type.label')}}:</label>
      <b-input-group>
        <b-form-select
          id="type-live"
          v-model="$v.type.$model"
          :state="validateState('type')"
          :options="$t('sonic.form.piping.type.options')"
          ref="focusThis"
          aria-describedby="type-feedback"
        >
          <template v-slot:first>
            <b-form-select-option
              :value="null"
              disabled
            >{{$t('sonic.form.piping.type.emptySelect')}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group>
      <div v-if="$v.type.$anyError" id="type-feedback">
        <small
          v-if="!$v.type.required"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.type.required')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
export default {
  name: "sonic.part.type",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    type: {
      get() {
        return this.part.type;
      },
      set(value) {
        this.$store.dispatch("setSonicPartFormType", value);
      }
    },
  },
  validations: {
    type: {
      required
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>