<template>
  <div id="partinletSizeDin">
    <b-form-group class="mb-2">
      <label
        for="size-inlet-live"
        v-if="part.type === 'pipe'"
      >{{$t('sonic.form.piping.inletSize.label', {type: $tc('sonic.form.piping.type.types',0)})}}:</label>
      <label
        for="size-inlet-live"
        v-if="part.type === 'valve'"
      >{{$t('sonic.form.piping.inletSize.label', {type: $tc('sonic.form.piping.type.types',1)})}}:</label>
      <label
        for="size-inlet-live"
        v-if="part.type === 'elbow'"
      >{{$t('sonic.form.piping.inletSize.label', {type: $tc('sonic.form.piping.type.types',2)})}}:</label>
      <label
        for="size-inlet-live"
        v-if="part.type === 'orifice'"
      >{{$t('sonic.form.piping.inletSize.label', {type: $tc('sonic.form.piping.type.types',3)})}}:</label>
      <label
        for="size-inlet-live"
        v-if="part.type === 'attemperator'"
      >{{$t('sonic.form.piping.inletSize.label', {type: $tc('sonic.form.piping.type.types',4)})}}:</label>
      <b-row>
        <b-col md="6">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-inlet-valve-live" v-model="pipeSizeSelectInlet">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.inletSize.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="item in sizingListInlet"
                  :key="item.id"
                  :value="item.DIN"
                >{{`DIN ${item.DIN}`}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
        <b-col md="6">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-outlet-valve-live" v-model="pipeNormSelectInlet">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.inletNorm.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="(item, key) in normListInlet"
                  :key="key"
                  :value="key"
                >{{`PN ${key}`}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
export default {
  name: "sonic.part.inletSize.din",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
      sizingListInlet: "getSonicSizingListInlet",
      normListInlet: "getSonicNormListInlet",
    }),
    pipeSizeSelectInlet: {
      get() {
        return this.part.standardInletSize;
      },
      set(value) {
        this.$store.dispatch("setSonicPartFormStandardInletSize", value);
      },
    },
    pipeNormSelectInlet: {
      get() {
        return this.part.standardInletNorm;
      },
      set(value) {
        this.$store.commit("setSonicPartFormStandardInletNorm", value);
      },
    },
  },
  validations: {
    inletSize: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>