<template>
  <div id="sonic.water.input.properties.form">
    <b-row>
      <b-col lg="4">
        <inlet-pressure />
      </b-col>
      <b-col lg="4">
        <inlet-temperature />
      </b-col>
      <b-col lg="4">
        <outlet-pressure />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="text-center">
        <invalid-button
        v-if="!isValid"
        class="mt-2"
        :text="$t('sonic.form.properties.buttons.invalid')"
        />
        <stored-button 
        v-else-if="stored" 
        class="mt-2"
        :text="$t('sonic.form.properties.buttons.stored')" 
        />
        <store-button 
        v-else-if="!loading"
        class="mt-2"
        :text="$t('sonic.form.properties.buttons.store')" 
        :active="isValid" 
        v-on:clicked="storeProperties()"
        />
        <loading-button 
        v-else 
        class="mt-2" 
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logic from '@/scripts'
import inletPressure from '@/views/sonic/form/properties/inletPressure'
import inletTemperature from '@/views/sonic/form/properties/inletTemperature'
import outletPressure from '@/views/sonic/form/properties/outletPressure'
import loadingButton from '@/components/general/loadingbutton'
import storeButton from '@/components/general/storeButton'
import storedButton from '@/components/general/storedButton'
import invalidButton from '@/components/general/invalidButton'
export default {
  name: 'sonic.water.input.properties.form',
  components: {
    inletPressure,
    inletTemperature,
    outletPressure,
    loadingButton,
    storeButton,
    storedButton,
    invalidButton
  },
  computed: {
    ...mapGetters({
      loading: "isSonicLoading",
      stored: "getSonicPropertiesStored",
      properties: "getSonicPropertiesForm",
    }),
    isValid() {
      return logic.input.sonic.properties.validate(this.properties.Pi, this.properties.Ti, this.properties.Pe)
    }
  },
  methods: {
    storeProperties() {
      this.$store.dispatch("storeSonicProjectDetails", "properties");
    },
  }
}
</script>

<style>

</style>