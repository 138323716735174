<template>
  <div id="sonic.water.input.piping.index">
    <b-row>
      <b-col md="12" class="text-center">
        <h1 class="display-4">{{ $t("sonic.project.piping.title") }}</h1>
        <hr />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" class="text-right">
        <b-button type="button" variant="success" @click="addPart" class="mb-2">
          <i class="fas fa-plus"></i>
          {{ $t("sonic.project.piping.buttons.addPart") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div v-if="piping.length > 0" class="table-responsive-lg">
          <b-row v-if="!isValid">
            <b-col md="12" class="text-center">
              <b-alert show variant="danger"
                >The piping is incorrect check if the part sizes match</b-alert
              >
            </b-col>
          </b-row>
          <piping-table />
          <b-row>
            <b-col xl="12" class="text-left">
              <b-button
                type="button"
                variant="success"
                @click="addPart"
                class="mb-2"
              >
                <i class="fas fa-plus"></i>
                {{ $t("sonic.project.piping.buttons.addPart") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="text-center">
              <invalid-button
                v-if="!isValid"
                class="mt-2"
                :text="$t('sonic.form.piping.buttons.invalid')"
              />
              <stored-button
                v-else-if="stored"
                class="mt-2"
                :text="$t('sonic.form.piping.buttons.stored')"
              />
              <store-button
                v-else-if="!loading"
                class="mt-2"
                :text="$t('sonic.form.piping.buttons.store')"
                :active="isValid"
                v-on:clicked="storePiping()"
              />
              <loading-button v-else class="mt-2" />
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <p class="text-center">
            {{ $t("sonic.project.piping.empty.table") }}
          </p>
        </div>
      </b-col>
    </b-row>
    <part-form />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import logic from "@/scripts";
import pipingTable from "./table";
import partForm from "./part";
import loadingButton from "@/components/general/loadingbutton";
import storeButton from "@/components/general/storeButton";
import storedButton from "@/components/general/storedButton";
import invalidButton from "@/components/general/invalidButton";
export default {
  name: "sonic.water.input.piping.index",
  components: {
    pipingTable,
    partForm,
    loadingButton,
    storeButton,
    storedButton,
    invalidButton,
  },
  computed: {
    ...mapGetters({
      piping: "getSonicPipingForm",
      stored: "getSonicPipingStored",
      loading: "isSonicLoading",
    }),
    pipingLength() {
      return this.piping.length > 0 ? true : false;
    },
    isValid() {
      return logic.input.sonic.piping.validate(this.piping);
    },
  },
  methods: {
    addPart() {
      this.$root.$emit("bv::show::modal", "modal-sonic-piping-add-part");
    },
    storePiping() {
      this.$store.dispatch("storeSonicProjectDetails", "piping");
    },
  },
};
</script>

<style>
</style>