<template>
  <div id="sonic.water.input.general.calculating">
    <b-modal
      v-model="isCalculating"
      centered
      no-close-on-backdrop
      :hide-header="true"
      :hide-footer="true"
      id="modal-calculating"
    >
      <template v-slot:default>
        <h4 class="text-center">{{$t('sonic.project.buttons.calculating')}}</h4>
        <div>
          <b-img
            center
            rounded="circle"
            width="300px"
            height="300px"
            :src="require('@/assets/water.jpg')"
            alt="..."
          ></b-img>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'sonic.water.input.general.calculating',
  computed: {
    ...mapGetters({
      isCalculating: "isSonicCalculating",
    })
  }
};
</script>

<style>
</style>