<template>
  <div id="sonic.input.water.index">
    <b-card>
      <template v-slot:header>
        <b-navbar toggleable="lg" class="text-center">
          <b-navbar-brand href="#">{{$t('sonic.project.input.title')}}</b-navbar-brand>

          <b-navbar-toggle target="sonic-input-nav"></b-navbar-toggle>

          <b-collapse id="sonic-input-nav" is-nav>
            <b-navbar-nav>
              <b-nav-item
                :active="isTabActive('general')"
                @click.prevent="setActive('general')"
              >{{$t('sonic.project.general.navTitle')}}</b-nav-item>
              <b-nav-item
                :active="isTabActive('properties')"
                @click.prevent="setActive('properties')"
              >{{$t('sonic.project.properties.navTitle')}}</b-nav-item>
              <b-nav-item
                :active="isTabActive('piping')"
                @click.prevent="setActive('piping')"
              >{{$t('sonic.project.piping.navTitle')}}</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <store-button
              v-if="!isProjectInProjects"
              class="mt-2 mr-2"
              :active="!isProjectInProjects"
              :text="$t('sonic.project.buttons.store')"
              v-on:clicked="linkProject()"
              />
              <invalid-button
              v-if="!isComplete"
              class="mt-2"
              :text="$t('sonic.project.buttons.invalid')"
              />
              <calculated-button 
              v-else-if="isCalculated"
              class="mt-2"
              :text="$t('sonic.project.buttons.calculated')"
              v-on:clicked="calculate()"
              />
              <calculate-button 
              v-else-if="!loading"
              class="mt-2"
              :text="$t('sonic.project.buttons.calculate')"
              :active="isComplete"
              v-on:clicked="calculate()"
              />
              <loading-button v-else class="mt-1" />
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      <div class="tab-content py-2" id="myTabContent">
        <div class="tab-pane fade" :class="{ 'active show': isTabActive('general') }" id="general">
          <General />
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': isTabActive('properties') }"
          id="general"
        >
          <Properties />
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isTabActive('piping') }" id="general">
          <Piping />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import General from "./general";
import Properties from "./properties";
import Piping from "./piping";
import loadingButton from "@/components/general/loadingbutton.vue";
import storeButton from "@/components/general/storeButton.vue"
import invalidButton from '@/components/general/invalidButton'
import calculateButton from "@/components/general/calculateButton.vue";
import calculatedButton from "@/components/general/calculatedButton.vue";
export default {
  name: "sonic.input.water.index",
  components: {
    General,
    Properties,
    Piping,
    loadingButton,
    storeButton,
    invalidButton,
    calculateButton,
    calculatedButton
  },
  computed: mapGetters({
    page: "getActivePageSonicInput",
    isProjectInProjects: "isSonicProjectInProjects",
    loading: "isSonicCalculating",
    isComplete: "isSonicProjectComplete",
    isCalculated: "isSonicCalculated"
  }),
  methods: {
      isTabActive(menuTab) {
          return this.page.active === menuTab
      },
      setActive(menuTab) {
        const data = {
          page: 'input',
          tab: menuTab
        }
        this.$store.dispatch('setSonicPageActiveTab',data)
      },
      linkProject() {
        this.$store.dispatch('linkSonicProject')
      },
      calculate() {
        this.$store.dispatch('runSonicCalculator')
      },
      
    }
};
</script>

<style>
</style>