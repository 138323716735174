<template>
  <div id="sonic.water.input.piping.part.form.general">
    <part-type />
    <div v-if="part.type!==null">
      <part-name />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import partType from '@/views/sonic/form/piping/type'
import partName from '@/views/sonic/form/piping/name'
export default {
  name: "sonic.water.input.piping.part.general",
  components: {
    partType,
    partName
  },
  computed: {
    ...mapGetters({
      part: "getSonicPartForm"
    }),
  }
};
</script>

<style>
</style>