<template>
  <div id="partName">
    <b-form-group class="mb-2" id="name-group">
        <label for="name-live">{{$t('sonic.form.piping.name.label')}}:</label>
        <b-input-group>
          <b-form-input
            id="name-live"
            type="text"
            v-model="$v.name.$model"
            :state="validateState('name')"
            aria-describedby="name-feedback"
          ></b-form-input>
        </b-input-group>
        <div v-if="$v.name.$anyError" id="name-feedback">
          <small v-if="!$v.name.required" class="form-text text-danger">{{$t('sonic.form.piping.name.required')}}</small>
          <small
            v-if="!$v.name.valid"
            class="form-text text-danger"
          >{{$t('sonic.form.piping.name.valid', {minLen: getLowerBoundery(), maxLen: getUpperBoundery()})}}</small>
        </div>
      </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "sonic.part.name",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    name: {
      get() {
        return this.part.name;
      },
      set(value) {
        this.$store.commit("setSonicPartFormName", value);
      }
    },
  },
  validations: {
    name: {
      required,
      valid() {
        return logic.input.sonic.parts.name.validate(this.part.name)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery() {
      return logic.input.sonic.parts.name.min
    },
    getUpperBoundery() {
      return logic.input.sonic.parts.name.max
    },
  }
};
</script>

<style>

</style>