<template>
  <div id="partOutletSizeCostum">
    <b-form-group class="mb-2">
      <label
        for="size-outlet-live"
        v-if="part.type === 'pipe'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',0)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'valve'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',1)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'elbow'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',2)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'orifice'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',3)})}}:</label>
      <label
        for="size-outlet-live"
        v-if="part.type === 'attemperator'"
      >{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',4)})}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-input-group-text tabindex="-1">
            <strong>{{$tc('units.diameter.short',0)}}</strong>
          </b-input-group-text>
        </template>
        <b-form-input
          id="size-outlet-live"
          type="number"
          v-model="$v.outletSize.$model"
          :state="validateState('outletSize')"
          aria-describedby="outlet-size-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.outletSize.$anyError" id="outlet-size-feedback">
        <small
          v-if="!$v.outletSize.required"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.outletSize.required')}}</small>
        <small
          v-if="!$v.outletSize.size"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.outletSize.between', { unit: $tc('units.diameter.short',0) })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "sonic.part.outletSize.costum",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    outletSize: {
      get() {
        return this.part.costumOutletSize;
      },
      set(value) {
        this.$store.commit("setSonicPartFormCostumOutletSize", value);
      },
    },
    outletSizeUnit: {
      get() {
        return this.part.costumOutletSizeUnit;
      },
      set(value) {
        this.$store.commit("setSonicPartFormCostumOutletSizeUnit", value);
      },
    },
  },
  validations: {
    outletSize: {
      required,
      size() {
        return (
          logic.input.sonic.parts.diameter.validate(this.part.costumOutletSize, this.part.costumOutletSizeUnit)
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>