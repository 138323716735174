<template>
  <div id="sonic.water.input.general.index">
    <b-row>
      <b-col md='12' class="text-center">
        <h1 class="display-4">{{$t('sonic.project.general.title')}}</h1>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="mb-2">
        <general-form />
      </b-col>
      <b-col md="6" class="mb-2">
        <b-row>
          <b-col md="1">
          </b-col>
          <b-col md="11">
            <check-list />
            <b-row>
              <b-col md="12">
                <b-button variant="success" @click="openShareLink()" :disabled="true"><i class="fas fa-share-alt"></i> {{$t('sonic.project.buttons.share')}}</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <calculating-modal />
    <share-modal />
  </div>
</template>

<script>
import generalForm from "./form"
import checkList from "./checklist"
import calculatingModal from './calculating'
import shareModal from './share'
export default {
  name: 'sonic.water.input.general.index',
  components: {
    generalForm,
    checkList,
    calculatingModal,
    shareModal
  }
}
</script>

<style>

</style>