<template>
  <div id="partFlowCoeffientElbow">
    <b-form-group
      class="mb-2"
      id="flow-coefficient-group"
    >
    <label for="flow-coefficient-live">{{$t('sonic.form.piping.flowCoefficient.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-input-group-text>
            <strong>{{$tc('units.flowCoefficient.short',2)}}</strong>
          </b-input-group-text>
        </template>
        <b-form-input
          id="flow-coefficient-live"
          type="number"
          v-model="$v.fc.$model"
          :state="validateState('fc')"
          aria-describedby="flow-coefficient-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.fc.$anyError" id="flow-coefficient-feedback">
        <small v-if="!$v.fc.required" class="form-text text-danger">{{$t('sonic.form.piping.flowCoefficient.required')}}</small>
      </div>
    </b-form-group>
    <div class="text-muted">
      {{$t('sonic.form.piping.flowCoefficient.elbow.description.title')}}:
      <ul>
        <li v-for="item in $t('sonic.form.piping.flowCoefficient.elbow.description.list')" :key="item">{{item}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "part.flowCoefficient.elbow",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    fc: {
      get() {
        return this.part.flowValue;
      },
      set(value) {
        this.$store.commit("setSonicPartFormFlowValue", value);
      }
    },
    fu: {
      get() {
        return this.part.flowUnit;
      },
      set(value) {
        this.$store.commit("setSonicPartFormFlowUnit", value);
      }
    },
  },
  validations: {
    fc: {
      required,
      valid() {
        return logic.input.sonic.parts.flowCoefficient.validate(this.part.type, this.part.flowValue, this.part.flowUnit)
      }
    },
    fuElbow: {
      required
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
}
</script>

<style>

</style>