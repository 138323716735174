<template>
  <div id="partLength">
    <b-form-group class="mb-2" id="length-group">
      <label for="type-live">{{$t('sonic.form.piping.length.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-input-group-text tabindex="-1">
            <strong>{{$tc('units.length.long',0)}}</strong>
          </b-input-group-text>
        </template>
        <b-form-input
          id="length-live"
          type="number"
          v-model="$v.length.$model"
          :state="validateState('length')"
          aria-describedby="length-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.length.$anyError" id="length-feedback">
        <small
          v-if="!$v.length.required"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.length.required')}}</small>
        <small
          v-if="!$v.length.valid"
          class="form-text text-danger"
        >{{$t('sonic.form.piping.length.between', {lower: getLowerBoundery(unit), upper: getUpperBoundery(unit), unit: $tc('units.length.short',getLengthUnit(unit))})}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
import logic from '@/scripts';
export default {
  name: "sonic.part.length",
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    length: {
      get() {
        return this.part.length;
      },
      set(value) {
        this.$store.commit("setSonicPartFormLength", value);
      }
    },
    unit: {
      get() {
        return this.part.lengthUnit
      },
      set(value) {
        this.$store.commit("setSonicPartFormLengthUnit", value)
      }
    }
  },
  validations: {
    length: {
      required,
      valid() {
        return logic.input.sonic.parts.length.validate(this.part.length, this.part.lengthUnit)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      return logic.input.partLength.getLowerBoundery(units)
    },
    getUpperBoundery(units) {
      return logic.input.partLength.getUpperBoundery(units)
    },
    getLengthUnit(units) {
      if (units === "ft") {
        return 1;
      } else if (units === "mm") {
        return 2;
      } else {
        return 0;
      }
    },
  }
};
</script>

<style>
</style>