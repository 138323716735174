<template>
  <div id="partInletSizing">
    <b-form-group>
      <label for="sizing-inlet-live">{{$t('sonic.form.piping.inletSizing.label')}}:</label>
      <b-input-group class="mb-2">
        <b-form-select
          id="sizing-nlet-live"
          v-model="$v.inletSizing.$model"
          :state="validateState('inletSizing')"
          :options="$t('sonic.form.piping.inletSizing.options')"
        ></b-form-select>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
export default {
  name: 'sonic.part.inlet.sizing',
  computed: {
    ...mapGetters({
      part: "getSonicPartForm"
    }),
    inletSizing: {
      get() {
        return this.part.inletSizing;
      },
      set(value) {
        this.$store.dispatch("setSonicPartFormInletSizing", { sizing: value });
        this.$store.dispatch("setSonicPartFormOutletSizing", { sizing: value });
      }
    }
  },
  validations: {
    inletSizing: {
      required
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
}
</script>

<style>

</style>