<template>
  <div id="sonic.water.input.piping.part.form.index">
    <general />
    <hr />
    <div v-if="part.type=='pipe'">
      <pipe />
    </div>
    <div v-if="part.type==='valve'">
      <valve />
    </div>
    <div v-if="part.type=='elbow'">
      <elbow />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import general from "./general";
import pipe from './pipe'
import valve from './valve'
import elbow from './elbow'
export default {
  name: "sonic.water.input.piping.part.form.index",
  components: {
    general,
    pipe,
    valve,
    elbow
  },
  computed: {
    ...mapGetters({
      part: "getSonicPartForm",
    }),
    
  },
};
</script>

<style>
</style>