<template>
    <b-button 
    variant="primary"
    :disabled="true"
    ><i class="fas fa-ban"></i> {{text}}</b-button>
</template>

<script>
export default {
    name: 'components.general.loadingButton',
    props: ['text']
}
</script>

<style>

</style>